import React from "react";
import AboutUsContainer from "../../container/AboutUs/index";

export default function index() {
    return (
        <div>
            <AboutUsContainer />
        </div>
    );
}
