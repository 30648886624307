import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Section from "../../../components/Section"; // Assuming Section component is already built
import Button from "../../../components/Button"; // Assuming Button component is already built
import Card from "../../../components/Card"; // Assuming Card component is already built

export default function TeamSection({ teamMembers }) {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const carouselRef = useRef(null);

    // Check screen size on load and resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024); // Laptop screens typically >= 1024px
        };

        handleResize(); // Check screen size on component mount
        window.addEventListener("resize", handleResize); // Listen for screen size changes

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect(() => {
    //     if (!isMobile) return;

    //     const interval = setInterval(() => {
    //         const maxScrollWidth = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;

    //         setScrollPosition((prevPosition) => {
    //             const newPosition = prevPosition + 1;
    //             if (newPosition >= maxScrollWidth) {
    //                 return 0;
    //             }
    //             return newPosition;
    //         });
    //     }, 10);

    //     return () => clearInterval(interval);
    // }, [isMobile]);

    // Scroll carousel when scrollPosition changes
    useEffect(() => {
        if (carouselRef.current && isMobile) {
            carouselRef.current.scrollLeft = scrollPosition;
        }
    }, [scrollPosition, isMobile]);

    // Slice to get top 3 team members
    const top3TeamMembers = teamMembers.slice(0, 3);

    return (
        <>
            <div className="md:flex lg:space-x-20 xl:pt-20 md:pt-0 h-full md:space-y-0">
                <div className="w-full md:w-1/2 text-3xl md:text-4xl font-bold  text-center md:text-left " data-aos="fade-right">
                    Our Passionate <span className="text-[#48A4F4]">Team</span>
                </div>
                <div
                    className="w-full md:w-1/2 items-start text-sm xl:text-2xl text-center md:text-left md:mt-0 px-10 md:px-0 flex justify-start"
                    data-aos="fade-left"
                >
                    <p className="font-light w-full">
                        With a team composition that is experienced in its field, we strive to maintain client trust to continue to provide the best
                        service for our clients.
                    </p>
                </div>
            </div>

            <div className="md:mt-10 py-4 h-full">
                <div className="md:h-full md:space-x-14 flex justify-center items-center max-md:flex-col overflow-hidden md:overflow-visible w-full px-4 md:px-0 max-md:max-w-[768px]">
                    {top3TeamMembers.map((data, index) => (
                        <div key={index} className="md: w-full" data-aos="zoom-in">
                            <Card
                                className="bg-white xl:w-[300px] lg:w-[280px] md:w-[200px] max-md:[300px] w-[300px] h-full "
                                imageSrc={data.imageSrc}
                                name={data.name}
                                position={data.position}
                            />
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className="mt-10  h-full">
                <div
                    ref={carouselRef}
                    className="md:h-full md:space-x-14 flex justify-center items-center overflow-hidden md:overflow-visible space-x-10 w-full px-4 md:px-0 max-md:max-w-[768px]"
                >
                    {top3TeamMembers.map((data, index) => (
                        <div key={index} className="md: w-full">
                            <Card
                                className="bg-white xl:w-[300px] lg:w-[280px] md:w-[200px] max-md:[200px] w-[200px] h-full "
                                imageSrc={data.imageSrc}
                                name={data.name}
                                position={data.position}
                            />
                        </div>
                    ))}
                    {isMobile &&
                        teamMembers.map((data, index) => (
                            <div key={index} className="md:w-full shrink-0 md:hidden">
                                <Card
                                    className="bg-white  xl:w-[300px] lg:w-[280px]  md:w-[200px] max-md:[200px] w-[200px] h-full"
                                    imageSrc={data.imageSrc}
                                    name={data.name}
                                    position={data.position}
                                />
                            </div>
                        ))}
                </div>
            </div> */}

            <div className="w-full flex justify-center items-center mt-10">
                <Link to={"/aboutus"}>
                    <Button title="Meet Our Team" status="primary" />
                </Link>
            </div>
        </>
    );
}
