import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { FaArrowLeft } from "react-icons/fa6";
import { industries } from "../../Data/data";
import Breadcrumbs from "../../components/Breadcrumbs";
import AOS from "aos";

export default function ServiceDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const service = industries.find((industry) => industry.id === Number(id));

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: "Services", path: "/services" },
        { label: service ? service.title : "Service Detail", path: `/services/${id}` },
    ];
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease",
        });
    }, []);

    return (
        <div className="h-full w-full ">
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden "
                className="md:h-[19rem] h-[7em] px-10 relative md:py-10"
                position="end"
            >
                <div className="absolute inset-0 bg-gradient-to-r from-[#0C2665] via-[#0b235b] to-[#0C2665]/50 from-5% via-30%  transition-all duration-1000 ease-in-out hover:blur-md z-20 "></div>

                <img className="absolute right-0 top-0 w-2/3 h-full object-cover z-10" src={service.backgroundImage} alt="" />
                <div className="absolute z-20">
                    <div className="text-4xl space-y-1">
                        <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-20 opacity-20"></div>
                        <div data-aos="fade-down">{service ? service.title : "Service Not Found"}</div>
                    </div>
                </div>
            </Header>

            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <div className="relative flex flex-col justify-center items-center xl:px-32 lg:px-20 md:py-20 p-5 font-normal w-full ">
                {service ? (
                    <div>
                        {service.commitmentStatement && (
                            <p className="font-normal" data-aos="fade-down">
                                {service.commitmentStatement}
                            </p>
                        )}
                        {service.services && service.services.length > 0 && (
                            <>
                                <h2 className="text-base font-normal" data-aos="fade-down">
                                    Our offerings:
                                </h2>
                                <ul className="pl-10">
                                    {service.services.map((area, index) => (
                                        <li key={index} className="list-disc font-normal">
                                            <p data-aos="fade-right">{area.title}</p>
                                            <p data-aos="fade-right">{area.description}</p>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {service.challengesStatement && (
                            <p className="mt-4" data-aos="fade-down">
                                {service.challengesStatement}
                            </p>
                        )}

                        {service.internationalStandards && service.internationalStandards.length > 0 && (
                            <>
                                <h2 className="mt-6 text-xl font-semibold" data-aos="fade-down">
                                    International Standards
                                </h2>
                                <div className="flex py-4 md:space-x-5 font-semibold text-[#073067] max-md:grid max-md:grid-cols-4 max-md:gap-2">
                                    {service.internationalStandards.map((standard, index) => (
                                        <div
                                            className="bg-blue-700 lg:px-4 md:px-2 sm:py-2 sm:px-1 py-1 ring-1 drop-shadow-lg rounded-xl max-md:rounded-lg  text-white font-normal md:text-xs text-xs text-center"
                                            key={index}
                                            data-aos="fade-right"
                                        >
                                            {standard}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        {service.expertiseAreas && service.expertiseAreas.length > 0 && (
                            <>
                                <h2 className="mt-6 text-base font-normal" data-aos="fade-down">
                                    Our expertise spans a wide array of critical areas, including:
                                </h2>
                                <ul className="pl-10">
                                    {service.expertiseAreas.map((area, index) => (
                                        <li
                                            key={index}
                                            className="list-disc font-normal"
                                            data-aos="fade-right"
                                            data-aos-delay="100"
                                            data-aos-offset="150"
                                        >
                                            {area.title && <h3 data-aos="fade-right">{area.title}</h3>}
                                            {area.description && <p data-aos="fade-right">{area.description}</p>}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {service.servicesProgram && service.servicesProgram.length > 0 && (
                            <>
                                <h2 className="mt-6 text-base font-normal" data-aos="fade-down">
                                    Our services encompass a wide array of critical topics designed to enhance the reliability, availability, and
                                    maintainability of power plant operations. These include:
                                </h2>
                                <ul className="pl-10">
                                    {service.servicesProgram.map((area, index) => (
                                        <li key={index} className="list-disc font-normal" data-aos="fade-right">
                                            {area.title && <h3>{area.title}</h3>}
                                            {area.description && <p data-aos="fade-right">{area.description}</p>}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {service.trainingPrograms && service.trainingPrograms.length > 0 && (
                            <>
                                <h2 className="mt-6 text-base font-normal" data-aos="fade-down">
                                    Training Programs Offered
                                </h2>
                                <h2 className="text-base font-normal" data-aos="fade-down">
                                    At CRI, we offer a range of specialized training programs tailored to meet the diverse needs of our clients. Our
                                    key training topics include:
                                </h2>
                                <ul className="pl-10" data-aos="fade-down">
                                    {service.trainingPrograms.map((area, index) => (
                                        <li key={index} className="list-disc font-normal" data-aos="fade-right">
                                            {area.title && <h3 data-aos="fade-right">{area.title}</h3>}
                                            {area.description && <p data-aos="fade-right">{area.description}</p>}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {/* Only render conclusionStatement if it exists */}
                        {service.conclusionStatement && (
                            <p className="mt-4 font-normal" data-aos="fade-right">
                                {service.conclusionStatement}
                            </p>
                        )}
                    </div>
                ) : (
                    <p>Service Detail not found.</p>
                )}
                {/* <button
                    className="absolute bottom-10 left-20 flex text-sm justify-center items-center space-x-5 hover:text-blue-400 hover:cursor-pointer"
                    onClick={handleBack}
                >
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
            </div>
        </div>
    );
}
