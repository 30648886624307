import React, { useEffect, useState } from "react";
import Header from "../../components/Header";

import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { FaGlobe, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";

import Button from "../../components/Button";
import { HiOutlineMail } from "react-icons/hi";

export default function ContactusContainer() {
    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        email: "",
        company: "",
        message: "",
    });

    const [modal, setmodal] = useState(false);

    const handleOpenModal = () => {
        setmodal(true);
    };
    const handleCloseModal = () => {
        setmodal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Contoh POST request ke API
        fetch("https://example.com/api/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        if (modal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [modal]);

    return (
        <div className="h-full">
            {modal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50"></div>

                    <div
                        className={`bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out xl:w-1/3 max-md:w-full  w-1/2 h-2/3 overflow-y-scroll ${
                            modal ? "scale-100 opacity-100 " : "scale-75 opacity-0"
                        }`}
                    >
                        <div className="w-full text-end">
                            <button onClick={handleCloseModal} className="mt-6 bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 ">
                                X
                            </button>
                        </div>
                        <form onSubmit={handleSubmit} className="mx-auto md:p-4 w-full space-y-5">
                            <h1 className="text-center text-2xl font-semibold">Contact Us</h1>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                                    Phone Number
                                </label>
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter your phone number"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                                    Company
                                </label>
                                <input
                                    type="text"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter your company"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                                    Message
                                </label>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter your message"
                                    rows="4"
                                    required
                                ></textarea>
                            </div>

                            <Button title="Submit" status="primary" />
                        </form>
                    </div>
                </div>
            )}
            <Header bgColor="bg-[#0C2665]" className="h-screen  md:h-[24rem] relative overflow-hidden max-md:hidden" position="end">
                <div className="h-full ">
                    <div className=" h-full z-30">
                        <div className="text-[#fffff] text-lg font-normal">Let's connect with CRI</div>
                        <div className="text-[#fffff] max-w-xl">Contact Us</div>
                    </div>
                </div>
            </Header>
            <Header bgColor="bg-[#0C2665]" className="h-screen  md:h-[24rem] relative overflow-hidden md:hidden" position="center">
                <div className="h-full ">
                    <div className=" h-full z-30">
                        <div className="text-[#fffff] text-lg font-normal">Let's connect with CRI</div>
                        <div className="text-[#fffff] max-w-xl">Contact Us</div>
                    </div>
                </div>
            </Header>
            <main className="text-lg text-[#616161] ">
                <section className="bg-[#F7FBFE] md:py-20 md:px-0 md:p-0 p-10 md:hidden ">
                    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto md:p-4 md:w-[43.75rem] w-full  ">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter your name"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                                Phone Number
                            </label>
                            <input
                                type="tel"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter your phone number"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter your email"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                                Company
                            </label>
                            <input
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter your company"
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                                Message
                            </label>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter your message"
                                rows="4"
                                required
                            ></textarea>
                        </div>

                        <Button title="Submit" status="primary" />
                    </form>
                </section>
                <section className="md:full xl:px-28  px-10 py-10 w-full h-full space-x-10 bg-[#F7FBFE] phy ">
                    <div className="md:flex w-full h-[500px] justify-center items-start ">
                        <div className="flex-1 h-full w-full ">
                            <iframe
                                className=" max-md:hidden h-full w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7872697772177!2d106.7739474753123!3d-6.291666193697371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f100559be6b3%3A0xff22a7e71874eb77!2sCliste%20Rekayasa%20Indonesia!5e0!3m2!1sid!2sid!4v1725620383180!5m2!1sid!2sid"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            />
                            <iframe
                                className="md:hidden h-full w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7872697772177!2d106.7739474753123!3d-6.291666193697371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f100559be6b3%3A0xff22a7e71874eb77!2sCliste%20Rekayasa%20Indonesia!5e0!3m2!1sid!2sid!4v1725620383180!5m2!1sid!2sid"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            />
                        </div>
                        <div className="lg:w-2/5 md:w-1/2  md:h-full max-md:hidden font-light text-xs xl:py-10 lg:p-8 md:p-5 bg-[#003478]  text-white  flex flex-col justify-between space-y-5">
                            <div className="w-full` space-y-5 items-center  lg:text-sm md:text-xs">
                                <div className="flex justify-center space-x-2 items-start">
                                    <FaMapMarkerAlt className="text-white h-5 w-5" />
                                    <div className="flex-1 space-y-2 px-2">
                                        <h3 className="font-semibold">Head Office :</h3>
                                        <p>Gandaria 8 Office Tower 8 Floor Jl. Sultan Iskandar Muda, Jakarta Selatan, 12440</p>
                                    </div>
                                </div>
                                <div className="flex justify-center space-x-2 items-start">
                                    <FaMapMarkerAlt className="text-white h-5 w-5" />
                                    <div className="flex-1 space-y-2 px-2">
                                        <h3 className="font-semibold">Remote Office :</h3>
                                        <p>
                                            <a href="https://maps.app.goo.gl/6wtr1ec1R6xBJvV59" className="hover:text-blue-200">
                                                Lebak Lestari Indah Residence Jl. Cempaka Lestari IV No.3 5, RT.14/RW.7, Lb. BulusKec, Kec. Cilandak,
                                                Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12440
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex space-x-4 items-center">
                                    <FaPhoneAlt className=" h-5 w-5" />
                                    <a href="tel:+622129851728">+62 21 29851728 / +62 812 1981 0869</a>
                                </div>
                                <div className="flex space-x-4 items-center">
                                    <FaGlobe className=" h-5 w-5" />
                                    <a href="https://cliste.co.id">www.cliste.co.id</a>
                                </div>
                                <div className="flex space-x-4 items-center">
                                    <IoMail className=" h-5 w-5" />
                                    <a href="mailto:office@cliste.co.id">office@cliste.co.id</a>
                                </div>
                            </div>
                            <div
                                className=" text-blue-900 md:text-base text-sm flex xl:w-1/3 w-1/2 font-semibold space-x-2 justify-center items-center bg-white px-1 py-2 rounded-md"
                                onClick={handleOpenModal}
                            >
                                <HiOutlineMail className="text-blue-800 lg:w-6 lg:h-6 w-3 h-3" />
                                <button className="">Contact Us</button>
                            </div>
                            <div className="w-full flex space-x-10 items-center text-xs font-normal ">
                                <div className="flex space-x-2  items-center">
                                    <FaInstagram className="w-5 h-5" />
                                    <div className="flex-1">
                                        {" "}
                                        <a href="https://www.instagram.com/clisterekayasaindonesia/">Clisterekayasaindonesia</a>
                                    </div>
                                </div>
                                <div className="flex space-x-2 items-center ">
                                    <FaLinkedin className="w-5 h-5" />
                                    <div className="flex-1">
                                        <a href="https://www.linkedin.com/company/pt-cliste-rekayasa-indonesia/mycompany/">
                                            PT Cliste Rekayasa Indonesia
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
