import React from "react";

export default function Header({ children, bgColor, className, height, position = "center" }) {
    const alignItems = position === "start" ? "items-start" : position === "end" ? "items-end" : "items-center"; // default center

    return (
        <div className={`relative w-full h-full ${bgColor} z-10 ${className}`}>
            <div className="text-white w-full h-full flex justify-center items-end ">
                <div className="w-full h-full text-5xl font-semibold z-50">
                    <div className={`w-full h-full md:py-16 flex justify-center ${alignItems} overflow-hidden `}>
                        <div className="flex-1 text-5xl leading-tight font-semibold md:px-14 px-10 md:py-5">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
