import React, { useState } from "react";
import Header from "../../../components/Header";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { vacancies } from "../../../Data/data";

function ApplyForm() {
    const { id } = useParams();
    const vacancy = vacancies.find((d) => d.id === Number(id));

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        contactNumber: "",
        address: "",
        university: "",
        major: "",
        availabilityPeriod: "",
        source: "",
        cv: null,
        transcript: null,
        agreement: false,
    });

    const fields = [
        { name: "fullName", label: "Full Name", type: "text", placeholder: "Type here" },
        { name: "email", label: "Email", type: "email", placeholder: "Type here" },
        { name: "contactNumber", label: "Contact Number", type: "tel", placeholder: "Type here" },
        { name: "address", label: "Address", type: "text", placeholder: "Type here" },
        { name: "university", label: "University", type: "text", placeholder: "Type here" },
        { name: "major", label: "Major", type: "text", placeholder: "Type here" },
        { name: "availabilityPeriod", label: "Availability Period", type: "text", placeholder: "Type here" },
        {
            name: "source",
            label: "How did you know about opportunities at CRM?",
            type: "select",
            options: ["Social Media", "Job Portal", "University Career Center", "Referral"],
        },
        { name: "cv", label: "Upload your CV (2MB max)", type: "file", acceptedFormats: ["doc", "docx", "pdf"] },
        { name: "transcript", label: "Upload your transcript (2MB max)", type: "file", acceptedFormats: ["doc", "docx", "pdf"] },
        {
            name: "agreement",
            tag: "By proceeding, I confirm that I have carefully read and agree to the Terms of Service and Privacy Policy.",
            type: "checkbox",
        },
    ];

    // Fungsi untuk menangani perubahan input
    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === "checkbox") {
            setFormData((prev) => ({ ...prev, [name]: checked }));
        } else if (type === "file") {
            setFormData((prev) => ({ ...prev, [name]: files[0] }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    // Fungsi untuk submit form
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="">
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[25rem] h-screen xl:px-32 md:px-14 px-10 max-md:hidden "
                position="end"
            ></Header>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="h-[7rem] xl:px-32 md:px-14 px-10 md:hidden"
                position="center"
            ></Header>

            <div className="py-20">
                <div className="text-4xl space-y-1 text-center text-[#616161] ">
                    <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-10"></div>
                    <div>{vacancy ? vacancy.title : "Service Not Found"}</div>
                </div>
                <form
                    className="relative grid grid-cols-1 justify-center items-center md:grid-cols-2 gap-6 py-20 xl:px-32 lg:px-24 md:px-14 max-md:px-5 my-10"
                    onSubmit={handleSubmit}
                >
                    {fields.map((field, index) => (
                        <div key={index} className="space-y-2 text-sm">
                            <label htmlFor={field.name} className="block  font-medium">
                                {field.label}
                            </label>

                            {/* Render input berdasarkan tipe */}
                            {field.type === "text" || field.type === "email" || field.type === "tel" ? (
                                <input
                                    type={field.type}
                                    name={field.name}
                                    id={field.name}
                                    placeholder={field.placeholder}
                                    value={formData[field.name] || ""}
                                    onChange={handleChange}
                                    className="border p-2 w-full"
                                />
                            ) : field.type === "select" ? (
                                <select
                                    name={field.name}
                                    id={field.name}
                                    value={formData[field.name] || ""}
                                    onChange={handleChange}
                                    className="border p-2 w-full"
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    {field.options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === "file" ? (
                                <input
                                    type="file"
                                    name={field.name}
                                    id={field.name}
                                    accept={field.acceptedFormats.map((ext) => `.${ext}`).join(", ")}
                                    onChange={handleChange}
                                    className="border p-2 w-full"
                                />
                            ) : field.type === "checkbox" ? (
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name={field.name}
                                        id={field.name}
                                        checked={formData[field.name] || false}
                                        onChange={handleChange}
                                        className="mr-2"
                                    />
                                    <label htmlFor={field.name} className="text-sm">
                                        {field.tag}
                                    </label>
                                </div>
                            ) : null}
                        </div>
                    ))}

                    {/* Tombol Submit pada satu baris penuh */}
                    <div className="md:absolute md:bottom-5 xl:left-32 lg:left-24 md:left-14">
                        <button type="submit" className="bg-blue-500 text-white px-5 py-2 rounded-md hover:bg-blue-600 w-full">
                            Submit Application
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ApplyForm;
