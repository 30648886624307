import React, { useState } from "react";

export default function Paper({ articles, handleReadMore }) {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    console.log("qwe", articles);

    return (
        <div className="text-black  h-screen ">
            <div className="space-y-4 md:px-0 px-5">
                <h1 className="font-semibold text-[1.75rem]">Paper</h1>
                <p className="text-[#757575] text-[16px]">
                    We serve best insight for your industries and learn more about asset management solutions.
                </p>
            </div>

            <div className="flex max-md:flex-col justify-start gap-6 w-full flex-wrap text-black max-md:px-5">
                <div className="lg:w-1/6 lg:flex-col flex justify-start items-start h-full w-full">
                    <div className="md:px-0 md py-10 px-5 ">
                        <h1>Tags</h1>
                        <input type="search" />
                        <div className="h-full lg:flex-col flex lg:justify-start lg:items-start justify-center items-center lg:space-y-2 space-x-4 lg:space-x-0">
                            <div className="flex items-center space-x-3">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="checkbox" className="text-gray-700 font-light">
                                    {/* {isChecked ? "Checked" : "Unchecked"} */}
                                    CRM
                                </label>
                            </div>
                            <div className="flex items-center space-x-3">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="checkbox" className="text-gray-700 font-light">
                                    {/* {isChecked ? "Checked" : "Unchecked"} */}
                                    FMEA
                                </label>
                            </div>
                            <div className="flex items-center space-x-3">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="checkbox" className="text-gray-700 font-light">
                                    {/* {isChecked ? "Checked" : "Unchecked"} */}
                                    CMMS
                                </label>
                            </div>
                            <div className="flex items-center space-x-3">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="checkbox" className="text-gray-700 font-light">
                                    {/* {isChecked ? "Checked" : "Unchecked"} */}
                                    CMMS
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:flex-1 bg-white w-full h-[600px] overflow-y-scroll space-y-4 md:p-2 max-md:px-3">
                    {articles.map((data, index) => (
                        <div
                            key={index}
                            className=" w-full shadow-xl rounded-xl hover:cursor-pointer overflow-hidden bg-[#fefefe]  ease-in-out duration-300 hover:translate-x-2 "
                            onClick={() => handleReadMore(data)}
                        >
                            <div className="p-5 md:text-lg space-y-8">
                                <div className="space space-y-2">
                                    <h1 className="">{data.title}</h1>
                                    <p className="md:text-sm font-extralight">{data.date}</p>
                                    <p className="md:text-sm font-light">
                                        Atuthor: IBP Mayun <span>ISBN: 1012-155</span>
                                    </p>
                                </div>
                                <div className="relative  text-sm text-left font-light space-y-3">
                                    <p>Tags</p>
                                    <div className="px-1 py-1 bg-[#CCD6E4] rounded-2xl w-20 flex justify-center items-center font-normal text-xs">
                                        CRM
                                    </div>
                                    <div className="absolute right-0 bottom-0 text-[#003478] font-semibold"> Read More</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
