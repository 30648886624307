import React from "react";
import Header from "../../components/Header";
import clisteLogo from "../../assets/3dLogoCliste.svg";
import BackgroundEngineering from "../../assets/bgEngineeringServices.png";
import { FaArrowRightLong } from "react-icons/fa6";

import OilandGas from "../../assets/servicesPage/Industries/OilandGas.svg";
import Transportation from "../../assets/servicesPage/Industries/Transportation.svg";
import Military from "../../assets/servicesPage/Industries/Military.svg";
import Education from "../../assets/servicesPage/Industries/Education.svg";
import Powerplant from "../../assets/servicesPage/Industries/Powerplant.svg";
import Petrochemical from "../../assets/servicesPage/Industries/Petrochemical.svg";

export default function SoftwareDevelopment() {
    const Industries = [
        { id: 1, title: "Oil and Gas", img: OilandGas },
        { id: 2, title: "Transportation", img: Transportation },
        { id: 3, title: "Military", img: Military },
        { id: 4, title: "Education", img: Education },
        { id: 5, title: "Power Plant", img: Powerplant },
        { id: 6, title: "Petrochemical", img: Petrochemical },
    ];

    return (
        <div>
            <Header
                className="flex items-end py-20 "
                bgColor="bg-gradient-to-b from-[#073067] to-[#1E6BCF]"
                height="22rem"
                image={BackgroundEngineering}
            >
                <div className="text-5xl font-semibold z-50">Software Development</div>
            </Header>
            <main className="py-5 px-36 grid justify-center items-center space-y-32">
                <div className="py-11 space-y-10 px-11">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem consequat
                        hendrerit congue accumsan amet purus. Nunc habitasse posuere sollicitudin
                        praesent pulvinar. Potenti suscipit nullam sollicitudin; congue malesuada
                        tempus. Magna vestibulum hac cursus natoque facilisi arcu aliquam sagittis.
                        Sollicitudin luctus suscipit amet elit habitant etiam? Pharetra arcu
                        faucibus euismod leo sagittis parturient.
                    </p>
                    <p>
                        Libero neque felis magna scelerisque montes. Mus congue donec lobortis
                        iaculis class felis posuere eleifend! Congue facilisis posuere dictum
                        condimentum gravida cras convallis. Per temp
                    </p>
                </div>
                <div className="relative space-y-10 max-w-[1200px] m-auto py-11">
                    <h1 className="text-center  text-4xl font-semibold">Our Digital Projects</h1>

                    <div className="flex overflow-x-hidden max-w-full space-x-14 px-4 ">
                        {Industries.map((data, index) => (
                            <div
                                key={index}
                                className="card w-[15.375rem] h-[15.375rem] bg-[#003478] rounded-xl text-white overflow-hidden flex-shrink-0"
                            >
                                <div className="grid py-5 space-y-3 h-full overflow-hidden">
                                    <div className="px-7">
                                        <h1 className="font-semibold text-xl">{data.title}</h1>
                                        <div className="flex justify-start items-center space-x-3">
                                            <span className="text-xs">More Details</span>
                                            <FaArrowRightLong className="text-xs" />
                                        </div>
                                    </div>
                                    <img
                                        className="relative translate-x-28 translate-y-9 "
                                        src={data.img}
                                        alt={data.title}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        </div>
    );
}
