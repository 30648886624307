import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumbs({ breadcrumbs, className }) {
    return (
        <nav className={`flex py-3 px-5 xl:px-32 md:px-20 justify-start ${className}`} aria-label="Breadcrumb">
            <ol className="list-reset flex md:text-base max-md:text-sm">
                {breadcrumbs.map((breadcrumb, index) => (
                    <li key={index} className="flex items-center">
                        {/* Breadcrumb Link */}
                        {index < breadcrumbs.length - 1 ? (
                            <Link to={breadcrumb.path} className=" text-gray-400 hover:text-slate-500">
                                {breadcrumb.label}
                            </Link>
                        ) : (
                            // Current breadcrumb (non-clickable)
                            <span className="text-slate-500">{breadcrumb.label}</span>
                        )}

                        {/* Divider (Arrow) */}
                        {index < breadcrumbs.length - 1 && <span className="mx-2 text-gray-400 ">/</span>}
                    </li>
                ))}
            </ol>
        </nav>
    );
}
