import React from "react";

export default function Card({ imageSrc, name, position, className = "w-[300px] h-[300px] md:w-[358px] md:h-[358px] " }) {
    return (
        <div className="flex justify-center items-center w-full h-full ">
            <div className="xl:h-[350px] lg:h-[330px] md:h-[230px]">
                <div className={`${className} `}>
                    <img className={`w-full object-cover`} src={imageSrc} alt={name} />
                </div>
                <div className="content w-full text-center ">
                    <div className="text-[#1E388B] font-bold lg:text-lg max-lg:text-base">{name}</div>
                    <div className="text-[#1E388B] md:text-lg font-normal max-lg:text-sm max-md:text-sm">{position}</div>
                </div>
            </div>
        </div>
    );
}
