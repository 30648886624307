import React from "react";
import CareerContainer from "../../container/Career";

export default function index() {
    return (
        <div>
            <CareerContainer />
        </div>
    );
}
