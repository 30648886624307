import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import AOS from "aos";
import aboutusBg from "../../assets/aboutusbg.png";
import visionMissionBg from "../../assets/visionMissionbg.png";

import Section from "../../components/Section";

import layerCard from "../../assets/layerCard.png";
import CardCarousel from "../../components/CardCarousel";

import { projects, teamMembers, listClients, CEO_DETAILS } from "../../Data/data";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function AboutUsCointainer() {
    const [activeItem, setActiveItem] = useState("Engineering");

    const menuItems = ["Engineering", "Procurement", "Training"];

    const [expanded, setExpanded] = useState(false); // State untuk mengatur apakah proyek diperluas

    const navigate = useNavigate(); // Initialize useNavigate

    const handleViewMore = () => {
        navigate("/aboutus/ceo-detail", { state: CEO_DETAILS }); // Navigate to detail page with state
    };

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "About Us", path: "/aboutus" },
    ];

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);

    return (
        <div className="h-full overflow-hidden">
            <Header bgColor="bg-white" className="h-screen md:h-[30rem] relative overflow-hidden max-md:hidden" position="end">
                <div className="h-full flex flex-col justify-center z-30 ">
                    <div className="space-y-3">
                        <div className="text-[#003478] text-lg md:text-xl font-normal" data-aos="fade-down">
                            About Us
                        </div>
                        <div className="text-[#003478] text-3xl md:text-4xl font-bold" data-aos="fade-down">
                            {" "}
                            We help your business grow
                        </div>
                        <div className="text-[#67B7FD] text-3xl md:text-4xl font-bold" data-aos="fade-down">
                            together.
                        </div>
                    </div>
                </div>
                <div className="absolute inset-0 -z-10 w-full h-full bg-gradient-to-r from-white to-white/40"></div>

                <img
                    className="absolute md:-right-24 md:top-0 inset-0 w-full h-full object-cover -z-50 md:hidden "
                    src={aboutusBg}
                    alt=""
                    style={{
                        filter: "blur(0px)",
                        maskImage: "linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%)",
                    }}
                />
                <img
                    className="absolute -right-24 top-0 -z-50 object-cover md:block hidden"
                    src={aboutusBg}
                    alt=""
                    style={{
                        filter: "blur(0px)",
                        maskImage: "linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%)",
                    }}
                />
            </Header>
            <Header bgColor="bg-white" className="h-screen md:h-[30rem] relative overflow-hidden md:px-32 px-15 md:hidden" position="center">
                <div className="h-full flex flex-col justify-center z-30 ">
                    <div className="space-y-3">
                        <div className="text-[#003478] text-lg md:text-xl font-normal" data-aos="fade-down">
                            About Us
                        </div>
                        <div className="text-[#003478] text-3xl md:text-4xl font-bold" data-aos="fade-down">
                            We help your business grow
                        </div>
                        <div className="text-[#67B7FD] text-3xl md:text-4xl font-bold" data-aos="fade-down">
                            together.
                        </div>
                    </div>
                </div>
                <div className="absolute inset-0 -z-10 w-full h-full bg-gradient-to-r from-white to-white/40"></div>

                <img
                    className="absolute md:-right-24 md:top-0 inset-0 w-full h-full object-cover -z-50 md:hidden "
                    src={aboutusBg}
                    alt=""
                    style={{
                        filter: "blur(0px)",
                        maskImage: "linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%)",
                    }}
                />
                <img
                    className="absolute -right-24 top-0 -z-50 object-cover md:block hidden"
                    src={aboutusBg}
                    alt=""
                    style={{
                        filter: "blur(0px)",
                        maskImage: "linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%)",
                    }}
                />
            </Header>

            <main className="text-lg space-y-5 text-[#616161] ">
                <Breadcrumbs breadcrumbs={breadcrumb} />
                <div className="md:h-[20rem] grid justify-center xl:px-32 lg:px-20 py-10 px-10">
                    <div className="md:text-lg text-lg font-normal space-y-5">
                        <div data-aos="zoom-in">
                            PT CRI provides Reliability based asset life cycle management through the conception, design, construction, commissioning,
                            operation and decommissioning phase.
                        </div>
                        <div className="md:text-lg text-lg font-normal" data-aos="zoom-in">
                            Aiming a word class support company, CRI provides Consultancy Services, Training, Software Renting, Software Development
                            as well as Man Power Support which well concern in Safety, Reliability, Availability, Maintainability and Asset Integrity
                            with goal of Maintenance & Reliability Excellence (MREX).
                        </div>
                        <div data-aos="zoom-in">These main concerns of CRI will deliver you the best of us.</div>
                    </div>
                </div>
                <Header className="h-full md:h-[30rem] py-10 md:py-0" bgColor="bg-blue-50">
                    <div className="w-full ">
                        <img className="absolute -z-50 top-0 left-0 object-cover h-full" src={visionMissionBg} alt="" />
                        <div className="absolute -z-10 top-0 right-0 w-full h-full  bg-[#093165CC]/80 "></div>
                    </div>
                    <div className="md:flex grid justify-center items-center md:space-x-10 space-y-4 md:space-y-0">
                        <div
                            className="text-white  md:w-[310px] md:h-[220px] lg:w-[460px] lg:h-[250px] xl:w-[594px] xl:h-[220px] w-full  grid place-items-center backdrop-blur-3xl bg-white/5 rounded-xl border border-blue-100 p-8"
                            data-aos="flip-right"
                        >
                            <h1 className="xl:text-4xl text-2xl">Vision</h1>
                            <p className="lg:text-xl  text-base md:font-light font-light text-center">
                                To become an internationally recognized firm which provides effective solutionsand contributes to Safety & Reliability
                                engineering development
                            </p>
                        </div>
                        <div
                            className="text-white  md:w-[310px] md:h-[220px] lg:w-[460px] lg:h-[250px] xl:w-[594px] xl:h-[220px] w-full  grid place-items-center backdrop-blur-3xl bg-white/5 rounded-xl border border-blue-100 p-8"
                            data-aos="flip-left"
                        >
                            <h1 className="md:text-4xl text-2xl">Mission</h1>
                            <p className="lg:text-xl  text-base md:font-light font-light text-center">
                                Deliver the highest quality of consultancy services, products and training to bring your business to Maintenance &
                                Reliability Excellence (MREX)
                            </p>
                        </div>
                    </div>
                </Header>
                <Section className={"xl:px-32 py-0 lgspace-x-20 "}>
                    <div className="lg:flex grid justify-center items-center xl:space-x-20 md:space-y-0 space-y-10">
                        <div className="flex justify-center items-center  ">
                            <div className="relative" data-aos="flip-right">
                                <img className="h-full max-lg:hidden" src={CEO_DETAILS.imgSrc} alt={CEO_DETAILS.name} />
                                <img
                                    className="absolute bottom-0 z-10 w-full h-full object-cover pointer-events-none rounded-2xl max-lg:hidden"
                                    src={layerCard}
                                    alt=""
                                />
                                <div className="absolute z-20 bottom-5 px-5 text-white md:text-xl text-lg max-lg:hidden">
                                    <h1>{CEO_DETAILS.name}</h1>
                                    <p className="text-sm font-normal">{CEO_DETAILS.role}</p>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-3/5 lg:space-x-10 space-y-10 font-normal lg:px-0 px-5">
                            <h1 className="text-4xl font-semibold lg:px-10 text-[#585858]" data-aos="fade-down">
                                Get to know our CEO
                            </h1>
                            <p data-aos="fade-down">
                                {CEO_DETAILS.summary.slice(0, 2).map((line, index) => (
                                    <span key={index} data-aos="fade-down">
                                        {line}
                                        <br />
                                    </span>
                                ))}
                            </p>
                            <button
                                onClick={handleViewMore}
                                className="bg-[#003478] hover:scale-110 text-white px-4 py-2 rounded hover:bg-[#003478af] transition duration-200"
                                data-aos="fade-down"
                            >
                                View more
                            </button>
                        </div>
                    </div>
                </Section>
                <Section className={"bg-[#fefefe] h-full md:px-10 max-md:px-5"}>
                    <div className="md:flex md:space-x-2 md:space-y-0 space-y-2 xl:px-52 font-normal">
                        <div className="my-auto text-4xl font-semibold xl:w-[350px] w-1/3 flex xl:space-x-3">
                            <div data-aos="fade-down">Our</div>

                            <span className="text-[#67B7FD]" data-aos="fade-left">
                                Excelence
                            </span>
                        </div>
                        <div className="md:flex-1" data-aos="fade-left">
                            Ever since CRI is established, our experts have contribute a lot in the development and improvement of Safety,
                            Reliability, Availability, Maintainability, Asset Integrity Management, Risk Based Management, and customized software.
                        </div>
                    </div>
                    <div className="flex md:flex-wrap overflow-x-auto md:overflow-x-visible justify-start md:justify-center items-center xl:px-22 lg:py-10 xl:space-x-14 lg:space-x-5  space-x-5">
                        {teamMembers.map((member, index) => (
                            <div className="flex-shrink-0" key={index}>
                                <div className="flex flex-col justify-center items-center">
                                    <img
                                        className="xl:w-[260px] lg:w-[200px] md:w-[130px] w-[300px] object-cover z-20"
                                        src={member.imageSrc}
                                        alt={member.name}
                                        data-aos="fade-left"
                                    />
                                    <div className="content w-full text-center xl:text-sm lg:text-[10px] md:text-[7px]" data-aos="fade-right">
                                        <div className="text-[#1E388B] font-bold">{member.name}</div>
                                        <div className="text-[#1E388B]">{member.position}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Section>
                <section className="w-full xl:px-32 xl:py-10 xl:space-y-5 px-10">
                    <div className="w-full md:flex justify-between md:space-y-0 space-y-5 text-4xl font-semibold ">
                        <div className="md:w-1/3">
                            CRI{" "}
                            <span className="text-[#67B7FD]" data-aos="fade-down">
                                Valuable Clients
                            </span>{" "}
                            & Partners
                        </div>
                        <div className="flex-1" data-aos="fade-left">
                            <p className="text-lg font-normal md:text-end text-start">We have a wide range of experience in helping many companies</p>
                        </div>
                    </div>
                    <div className="w-full">
                        <CardCarousel listclient={listClients} />
                    </div>
                </section>
                <section className="md:px-14 md:py-28 py-10 px-5 md:h-full bg-gradient-to-b to-[#0D294F] from-[#0E4691]/95 w-full md:space-y-10 space-y-5 grid justify-center items-center">
                    <div className="space-y-3">
                        <h1 className="text-5xl text-white font-semibold" data-aos="fade-right">
                            Explore Our Projects
                        </h1>
                        <p className="text-white font-normal md:text-lg text-sm" data-aos="fade-right">
                            We make an impact on the reliability industry and help the development of large companies
                        </p>
                    </div>
                    <div className="md:space-y-10 space-y-5">
                        <div className="flex md:justify-start justify-center md:space-x-10 items-center md:text-lg text-sm space-x-2  text-[#67B7FD]">
                            {menuItems.map((item) => (
                                <div
                                    key={item}
                                    className={`cursor-pointer md:p-4 p-3 rounded-xl font-medium ${
                                        activeItem === item ? "text-[#67B7FD] border-[#67B7FD] border" : "text-[#C2C2C2]"
                                    }`}
                                    onClick={() => setActiveItem(item)}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>

                        <div
                            className={`grid  lg:grid-cols-2 md:w-full md:gap-x-7 gap-y-3 overflow-hidden ${
                                expanded ? "md:h-[500px] h-[500px] overflow-y-scroll" : "md:h-[300px] h-[300px]"
                            } transition-all duration-300 ease-in-out`}
                        >
                            {projects[activeItem].slice(0, expanded ? 10 : 4).map((project, index) => (
                                <div
                                    key={index}
                                    className="flex md:w-full px-3 md:h-32 backdrop-blur-3xl bg-white/10 rounded-xl md:px-6 py-4 space-x-3 "
                                    data-aos="flip-right"
                                >
                                    <div className="md:w-24 md:h-24 bg-white rounded-lg overflow-hidden flex justify-center items-center md:p-1">
                                        <img className="w-full object-cover p-3" src={project.imgSrc} alt={project.title} />
                                    </div>
                                    <div className="flex-1 md:text-[16px] text-xs py-1 md:leading-loose md:space-y-3">
                                        <p className="text-white font-semibold xl:text-base lg:text-xs md:text-sm ">{project.title}</p>
                                        <p className="text-[#C2C2C2] font-light xl:text-base lg:text-xs md:text-sm ">{project.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="w-full flex justify-center items-center">
                        <button
                            onClick={() => setExpanded(!expanded)}
                            className="bg-white hover:bg-slate-300 hover:ease-in duration-100 md:text-[16px] text-sm px-4 py-2 rounded-lg"
                        >
                            {expanded ? "View Less" : "View More"}
                        </button>
                    </div>
                </section>
            </main>
        </div>
    );
}
