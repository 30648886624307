import React, { useEffect, useRef, useState } from "react";
import Card from "../components/Card";

export default function Carousel({ listclient }) {
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const carouselRef = useRef(null);
    const scrollSpeed = 2;

    // Infinite scroll logic
    const handleAutoScroll = () => {
        if (carouselRef.current && !isDragging) {
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            if (carouselRef.current.scrollLeft >= maxScrollLeft) {
                carouselRef.current.scrollLeft = 0; // Loop back to start
            } else {
                carouselRef.current.scrollLeft += scrollSpeed; // Auto-scroll
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(handleAutoScroll, 30);
        return () => clearInterval(interval);
    }, [isDragging]);
    // Handle mouse/touch drag start
    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartX(e.pageX || e.touches[0].pageX);
        setScrollLeft(carouselRef.current.scrollLeft);
    };

    // Handle dragging
    const handleDragging = (e) => {
        if (!isDragging) return;
        const x = e.pageX || e.touches[0].pageX;
        const walk = (x - startX) * 1.5; // Adjust drag sensitivity
        carouselRef.current.scrollLeft = scrollLeft - walk; // Update scroll position based on dragging
    };

    // Handle mouse/touch drag end
    const handleDragEnd = () => {
        setIsDragging(false);
    };

    return (
        <>
            <div className="mt-10 h-full overflow-hidden">
                <div
                    ref={carouselRef}
                    className="flex items-center space-x-14 w-full px-4 md:px-0 overflow-hidden"
                    onMouseDown={handleDragStart}
                    onMouseMove={handleDragging}
                    onMouseUp={handleDragEnd}
                    onMouseLeave={handleDragEnd}
                    onTouchStart={handleDragStart}
                    onTouchMove={handleDragging}
                    onTouchEnd={handleDragEnd}
                >
                    {listclient.concat(listclient).map((data, index) => (
                        <div key={index} className="shrink-0">
                            <div className="flex justify-center items-center w-full h-full ">
                                <div className={`bg-white  xl:w-[50px] lg:w-[45px] w-[40px] h-full`}>
                                    <img className={`w-full object-cover`} src={data.imageSrc} alt={data.name} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
