import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import clisteLogo from "../assets/cliste-logo.png";
import { useMediaQuery } from "react-responsive";
import { FaBars, FaSearch, FaTimes } from "react-icons/fa"; // Icon for hamburger and close menu
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export default function NavBar() {
    const [language, setLanguage] = useState("EN");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchActive, setSearchActive] = useState(false);

    const location = useLocation();
    const [activePage, setActivePage] = useState("");

    const isMobile = useMediaQuery({ maxWidth: 899 });
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuItems = [
        { name: "Overview", key: "overview", path: "/" },
        { name: "Services", key: "services", path: "/services" },
        { name: "About Us", key: "aboutUs", path: "/aboutus" },
        { name: "Our Insight", key: "OurInsight", path: "/ourinsight" },
        { name: "Career", key: "career", path: "/career" },
        { name: "Contact Us", key: "contactus", path: "/contactus" },
    ];

    useEffect(() => {
        const currentItem = menuItems.find((item) => item.path === location.pathname);
        if (currentItem) {
            setActivePage(currentItem.key);
        }
    }, [location.pathname, menuItems]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        setDropdownOpen(false); // Tutup dropdown setelah memilih bahasa
    };

    const toggleSearch = () => {
        setSearchActive(!searchActive); // Toggle state search
    };

    return (
        <div className="absolute bg-gradient-to-b from-black/65 to-transparent w-full flex items-center justify-between py-3 px-6 md:px-10 text-white z-50">
            <div>
                <img className="w-32" src={clisteLogo} alt="Cliste Logo" />
            </div>

            {isMobile ? (
                <button onClick={toggleMenu} className="text-white focus:outline-none z-30">
                    {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </button>
            ) : null}

            <div
                className={`${
                    isMobile
                        ? `fixed inset-0 bg-black bg-opacity-90 z-20 flex flex-col items-center justify-center space-y-6 transform ${
                              isMenuOpen ? "translate-x-0" : "-translate-x-full"
                          } transition-transform duration-300`
                        : "flex lg:gap-2 md:gap-1 lg:text-sm md:text-xs font-medium flex-1 justify-start lg:px-10 md:px-3 "
                }`}
            >
                {menuItems.map((item) => (
                    <Link
                        to={item.path}
                        key={item.key}
                        onClick={() => {
                            setActivePage(item.key);
                            setIsMenuOpen(false);
                        }}
                        className={`px-4 py-2 rounded duration-300 ease-in-out cursor-pointer hover:text-white hover:bg-[#4a7cae] hover:rounded-lg ${
                            activePage === item.key
                                ? "text-[#67B7FD] border-2 rounded-lg border-[#67B7FD] hover:border-[#4a7cae]"
                                : "hover:text-[#78B4F0]"
                        }`}
                    >
                        {item.name}
                    </Link>
                ))}
            </div>

            {/* Right Section (Language and Search Buttons) */}
            {!isMobile && (
                <div className="flex gap-4 items-center">
                    {/* Language Dropdown */}
                    <div className="relative">
                        <div
                            onClick={toggleDropdown}
                            className="flex justify-center items-center bg-[#FFFFFF1A]/10 text-white hover:border-white px-5 py-2 hover:border-2 rounded-lg space-x-2 cursor-pointer"
                        >
                            <span>{language}</span>
                            {dropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </div>

                        {dropdownOpen && (
                            <div className="absolute mt-2 w-full bg-[#FFFFFF1A]/10 text-white rounded-lg shadow-lg">
                                <ul>
                                    <li className="px-5 py-2 hover:bg-[#FFFFFF33] cursor-pointer" onClick={() => handleLanguageChange("EN")}>
                                        EN
                                    </li>
                                    <li className="px-5 py-2 hover:bg-[#FFFFFF33] cursor-pointer" onClick={() => handleLanguageChange("ID")}>
                                        ID
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    {/* Search Button with Animation */}
                    <div className={`relative flex items-center transition-all duration-300 ease-in-out ${searchActive ? "w-64" : "w-12"}`}>
                        <input
                            type="text"
                            className={`absolute  bg-[#FFFFFF1A]/0 h-full font-normal text-sm  text-white border-none outline-none transition-all duration-300 ease-in-out z-10 ${
                                searchActive ? "opacity-100 w-2/3 h-full pl-4" : "opacity-0 w-0"
                            }`}
                            placeholder="Search..."
                        />
                        <button
                            onClick={toggleSearch}
                            className="flex items-center justify-end w-full  bg-[#FFFFFF1A]/10 text-white hover:border-white px-4 py-2 hover:border-2 rounded-lg transition-all duration-300 ease-in-out z-0"
                        >
                            <FaSearch />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
