import React, { useEffect } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";
import AOS from "aos";
import { CEO_DETAILS } from "../../Data/data";
import Header from "../../components/Header";
import { FaArrowLeft } from "react-icons/fa";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function DetailCeo() {
    const location = useLocation();
    const { name, role, summary, specialties, workExamples, imgSrc, imgBanner } = CEO_DETAILS; // Use the CEO_DETAILS directly

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "About Us", path: "/aboutus" },
        { label: "CEO Detail", path: "/aboutus/ceo-detail" },
    ];

    if (!name || !role || !summary || !imgSrc) {
        return (
            <div className="flex flex-col items-center p-10">
                <h1 className="text-4xl font-semibold">Data not available</h1>
            </div>
        );
    }

    return (
        <div className="h-full overflow-hidden">
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="h-[5.3rem] max-md:h-[7rem] xl:px-32 md:px-14 px-10"
            >
                <div className="text-4xl space-y-1">
                    <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-10"></div>
                    <div>{CEO_DETAILS ? CEO_DETAILS.title : "Service Not Found"}</div>
                </div>
            </Header>
            <Breadcrumbs breadcrumbs={breadcrumb} />
            <div className="flex flex-col items-center xl:px-32 lg:px-24 md:px-16 px-5  md:py-20 p-10 max-md:py-10 max-md:px-5  font-normal">
                <img src={imgBanner} alt={name} className="rounded-lg mb-5 w-full" data-aos="fade-down" />
                <h1 className="lg:text-4xl font-semibold" data-aos="zoom-in">
                    {name}
                </h1>
                <p className="md:text-lg max-lg:text-base font-normal" data-aos="zoom-in">
                    {role}
                </p>
                <div>
                    <h1 className="font-bold md:text-xl" data-aos="fade-right">
                        Summary
                    </h1>
                    {summary && summary.length > 0 ? (
                        summary.map((data, index) => (
                            <p key={index} className="lg:text-lg max-lg:text-base font-normal md:px-4 py-3" data-aos="fade-right">
                                {data}
                            </p>
                        ))
                    ) : (
                        <li className="text-lg font-normal" data-aos="zoom-in">
                            No work examples available.
                        </li>
                    )}
                </div>

                <h2 className="mt-8 md:text-xl font-semibold w-full" data-aos="fade-right">
                    Examples of Work
                </h2>
                <ol className="mt-2 list-decimal md:px-10 px-5 space-y-4" data-aos="fade-right">
                    {workExamples && workExamples.length > 0 ? (
                        workExamples.map((example, index) => (
                            <li key={index} className="lg:text-lg max-lg:text-base font-normal md:px-4">
                                {example}
                            </li>
                        ))
                    ) : (
                        <li className="text-lg font-normal">No work examples available.</li>
                    )}
                </ol>
            </div>
        </div>
    );
}
