import React from "react";

export default function OurInsightNavbar({ filterList, selectedFilterList, setSelectedFilterList }) {
    return (
        <div className="flex justify-center gap-10 w-full overflow-y-auto py-4 ">
            {filterList.map((data, index) =>
                selectedFilterList.id === data.id ? (
                    <button className="px-8 py-3 text-[#67B7FD] ring-[#67B7FD] ring-2 text-sm text-clip min-w-fit rounded-lg duration-300">
                        {data.label}
                    </button>
                ) : (
                    <button
                        className="px-8 py-3 bg-white text-[#C2C2C2] border text-sm text-clip min-w-fit rounded-lg duration-300 shadow-sm shadow-[#67B7FD]"
                        onClick={() => {
                            setSelectedFilterList(data);
                        }}
                    >
                        {data.label}
                    </button>
                )
            )}
        </div>
    );
}
