import React, { useEffect, useState } from "react";
import Hero from "../../sections/Hero/index";
import InfoCard from "../../components/InfoCard";
import AOS from "aos";
import logocri from "../../assets/3dLogoCliste.svg";

import carrerAtCRI from "../../assets/carrerAtCri.jpeg";
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import Section from "../../components/Section";
import TeamSection from "./Section/TeamSection";

import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { teamMembers, articlePaper, infoCardData } from "../../Data/data";

export default function CRIComponent() {
    const [showFullText, setShowFullText] = useState(false);
    const navigate = useNavigate();

    const paragraphs = [
        "Since its inception as pioneer company in Reliability in January 2014, CRI has been at the forefront of advancing the Reliability industry in Indonesia and beyond. With a commitment to excellence and innovation, we have successfully completed over 100 projects, serving a diverse range of clients in Indonesia as well as in Malaysia, Thailand, and Qatar",
        "Our extensive portfolio showcases our expertise in delivering high-quality solutions tailored to meet the unique needs of each client. By specializing in areas such as Failure Modes and Effects Analysis (FMEA), Root Cause Failure Analysis (RCFA), and Reliability-Centered Maintenance (RCM), we have significantly enhanced the operational efficiency and safety standards of numerous organizations.",
        "CRI’s contributions extend beyond project completion. We are dedicated to fostering a culture of continuous improvement and knowledge sharing within the industry. Our initiatives in improving the visibility of Maintenance & Reliability Data and implementing comprehensive Equipment/Machinery Healthiness programs have set new benchmarks for reliability and safety.",
        "In addition, CRI has developed a web-based, customizable Computerized Maintenance Management System (CMMS) known as IMEX. This innovative platform includes a wide range of assessment modules such as Asset Register, Spare Part Management, Equipment Criticality Analysis (ECA), Safety Environment Critical Element (SECE), Reliability-Centered Maintenance (RCM), Life Data Analysis (LDA), Risk-Based Inspection (RBI), Reliability Block Diagram (RBD), Corrosion Management, Integrity Operating Window (IOW), and Inspection Testing & Preventive Maintenance (ITPM). These features make CRI IMEX a one-stop solution for our clients’ business needs.",
        "Through our strategic partnerships and unwavering commitment to quality, CRI has established itself as a trusted leader in the Reliability industry. Our work not only supports the operational success of our clients but also contributes to the overall advancement of industry standards in the region.",
    ];

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };

    const handleReadMoreArticle = (article) => {
        navigate(`/ourInsight/article/${article.id}`, { state: { article } });
    };

    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true,
            offset: 100,
            easing: "ease-in-out",
        });
    }, []);

    return (
        <div className="w-full h-full overflow-hidden">
            <div className="xl:h-screen lg:h-[100vh] md:h-[80vh] max-md:h-screen w-full flex justify-center items-center" data-aos="fade-down">
                <div className=" absolute xl:top-0 lg:-top-10 md:-top-20  lg:h-4/6 md:h-3/6 h-screen max-md:hidden w-full bg-gradient-to-b from-[#073067] to-[#1E6BCF]"></div>
                <Hero className={" md:px-12 xl:h-[72vh] lg:h-[65vh] h-[55vh] max-md:h-full w-full  "} />
            </div>
            <div className="h-full flex justify-center items-end text-center lg:pb-32  md:py-10 py-6 xl:px-32 px-5">
                <div className="space-y-10">
                    {/* <div className="bg-[#0B3267] w-40 mx-auto text-white rounded-xl px-5 py-2 md:text-xl">CRI's Journey</div> */}
                    <div className="bg-[#0B3267] drop-shadow-sm w-44 mx-auto text-white rounded-xl px-5 py-2 md:text-xl" data-aos="zoom-in-down">
                        CRI's Journey
                    </div>
                    {/* <div className=" w-56 mx-auto text-[#0B3267] font-semibold lg:text-2xl rounded-xl px-5 py-2 md:text-xl">CRI's Journey</div> */}
                    <h1 className="md:text-4xl text-2xl font-semibold md:px-10" data-aos="zoom-in">
                        CRI's Role in the Reliability or Asset Management Industry in Indonesia
                    </h1>
                    <div
                        className={`md:text-lg text-sm md:px-10 font-normal space-y-4 text-justify transition-all duration-300 text-[#585858] ${
                            showFullText ? "fade-enter fade-enter-active" : "fade-exit fade-exit-active"
                        }`}
                    >
                        {showFullText
                            ? paragraphs.map((para, index) => (
                                  <p data-aos="fade-down" key={index}>
                                      {para}
                                  </p>
                              ))
                            : paragraphs.slice(0, 2).map((para, index) => (
                                  <p key={index} data-aos="fade-down">
                                      {para}
                                  </p>
                              ))}
                    </div>
                    <div className="flex justify-center">
                        <button onClick={toggleFullText} className="flex items-center space-x-3  px-4 py-2 rounded-lg">
                            <span>{showFullText ? "Read Less" : "Read More"}</span>
                            {showFullText ? <FaArrowUp className="duration-300 text-[#585858]/80" /> : <FaArrowDown className="duration-300" />}
                        </button>
                    </div>
                </div>
            </div>

            <Section className={"relative bg-gradient-to-t from-[#09346E] to to-[#1857AB] md:py-32"} containerClassName="h-full w-full">
                <div className="h-full flex flex-col justify-center items-start gap-10 2xl:px-32">
                    <h1 className="xl:text-5xl text-4xl font-bold text-white max-lg:text-center w-full" data-aos="fade-right">
                        Our Achievements
                    </h1>
                    <div className="w-full md:flex lg:justify-start md:justify-center  grid justify-center items-center md:space-x-10 gap-5 ">
                        {infoCardData.map((item, index) => (
                            <div key={index}>
                                <InfoCard className="xl:w-52 xl:h-56 w-40 h-40 " data-aos="zoom-in-up">
                                    <div className="text-4xl sm:text-5xl md:text-5xl font-bold" data-aos="zoom-in">
                                        {item.title}
                                    </div>
                                    <div className="text-base sm:text-lg  md:text-xl" data-aos="zoom-in">
                                        {item.subtitle}
                                    </div>
                                    <div className="text-sm sm:text-base md:text-lg lg:text-md xl:text-md font-extralight" data-aos="zoom-in">
                                        {item.description}
                                    </div>
                                </InfoCard>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="absolute right-0 bottom-10 drop-shadow-lg shadow-black" data-aos="fade-left">
                    <img className="xl:w-[400px] max-lg:hidden" src={logocri} alt="" />
                </div>
            </Section>

            {/* Section3 */}
            <Section className="xl:px-40 md:px-10 w-full h-full max" containerClassName="w-full justify-center item-center md:space-y-20 space-y-3">
                <TeamSection teamMembers={teamMembers} />
            </Section>

            <Section className="bg-gradient-to-tr from-[#0D294F] from-30% to-[#0E4691] to-90% xl:px-36 lg:px-10 px-5 xl:py-40 md:py-10  ">
                <div className="lg:flex max-md:grid justify-center items-start lg:space-x-10 space-y-10 md:px-0 md:py-0 py-20">
                    <div className="lg:flex-1" data-aos="fade-right">
                        <img className="rounded-xl w-full lg:h-[568px] md:h-full object-cover md:block hidden" src={carrerAtCRI} alt="" />
                    </div>
                    <div className="lg:w-1/2 text-white md:space-y-10 space-y-5 md:text-xl text-sm font-normal" data-aos="fade-left">
                        <div className="text-5xl font-semibold">Career at CRI</div>
                        <div className=" font-light" data-aos="zoom-in-up">
                            Joining CRI is an opportunity to work with people who, our consultants come from the best stories in Indonesia.
                        </div>
                        <div className=" font-light" data-aos="zoom-in-up">
                            Joining CRI is an opportunity to work with people who, our consultants come from the best stories in Indonesia. When you
                            work here, you can help the development of important institutions in Indonesia and other countries to grow sustainably. We
                            welcome applications from great talents and experienced professionals
                        </div>
                        <div>
                            <Link to={"/career"}>
                                <Button title="Explore CRI" status="secondary" className={"mx-auto font-normal"} />
                            </Link>
                        </div>
                    </div>
                </div>
            </Section>

            <Section className="bg-[#fefefe] w-full xl:px-36 md:px-10 md:py-40" containerClassName="h-full w-full">
                <div className="xl:space-y-20 md:space-y-10 w-full">
                    {/* Heading Section */}
                    <div className="h-1/4 text-lg font-bold md:space-y-5 md:text-[42px] text-center px-5 md:px-0">
                        <div className="flex flex-wrap justify-center items-center text-3xl md:text-5xl">
                            <h1 data-aos="fade-down">
                                Stay keep updated with the latest trends and information in <span className="text-[#67B7FD]">Asset</span>{" "}
                                <span className="text-[#67B7FD]">Management Industries</span>
                            </h1>
                        </div>
                        <p className="md:text-xl text-base font-normal text-[#9E9E9E]" data-aos="zoom-in">
                            We serve the best insight for your industries and learn more about asset management solutions.
                        </p>
                    </div>

                    {/* Card Section */}
                    <div className="md:flex max-md:flex-col md:h-full w-full md:space-x-5 flex justify-center items-center overflow-x-scroll md:overflow-visible xl:space-x-10  px-4 md:px-0">
                        {articlePaper.map((item, index) => (
                            <div
                                key={index}
                                className="xl:max-w-[300px] lg:max-w-[280px] md:max-w-[200px] max-w-[350px] shadow-lg drop-shadow-lg rounded-xl hover:cursor-pointer flex-shrink-0 max-md:my-5"
                                onClick={() => handleReadMoreArticle(item)}
                                data-aos="zoom-in-up"
                                data-aos-easing="linear"
                            >
                                <div className="relative overflow-hidden ">
                                    <a
                                        className="absolute text-white border px-7 py-1 rounded-xl bg-white/10 backdrop-blur-sm z-10 hover:bg-white/35 transition ease-in-outs top-5 left-6"
                                        href={item.link}
                                    >
                                        Article
                                    </a>
                                    <img
                                        className="w-full  rounded-t-xl object-cover transform transition duration-500 hover:scale-110"
                                        src={item.src}
                                        alt=""
                                    />
                                </div>
                                <div className="bg-white rounded-b-xl xl:space-y-5 lg:space-y-4 md:space-y-2 xl:px-5 px-2 py-3">
                                    <div className="text-[#757575] text-base  xl:text-base md:text-sm">{item.date}</div>
                                    <div className="title xl:text-lg lg:text-base md:text-sm text-base md:font-bold font-semibold text-[#676767]">
                                        {item.title}
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center md:text-base text-sm text-[#616161] space-x-1">
                                            <div className="xl:text-base lg:text-base md:text-base text-black">{item.readTime}</div>
                                        </div>
                                        <div className="flex-1 text-base xl:text-base lg:text-base md:text-sm md:font-medium text-[#1D4888] font-normal text-end">
                                            <a href={item.link}>Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Button Section */}
                    <div className="flex justify-center items-center">
                        <Link to={"/ourinsight"}>
                            <Button title="See Our Insight" status="primary" className="font-normal" />
                        </Link>
                    </div>
                </div>
            </Section>
        </div>
    );
}
