import React from "react";

export default function Button({ title, status, type, className, children, onClick }) {
    return (
        <button
            className={` ${
                status === "primary"
                    ? "bg-[#003478] hover:bg-[#002654] text-white hover:text-white hover:bg-primaryhover border border-primary hover:border-primaryhover hover:scale-105"
                    : status === "secondary"
                    ? "bg-white hover:bg-[#ffffff] text-[#003478] hover:text-[#002654] border border-primary hover:border-primary hover:scale-105"
                    : status === "danger"
                    ? "bg-[#DC271E] text-white hover:bg-[#b7251d] hover:text-white hover:scale-105"
                    : status === "danger-secondary"
                    ? "bg-white hover:bg-[#E4EAEC] text-[#DC271E] hover:text-[#b7251d] border border-[#b7251d] hover:border-[#b7251d] hover:scale-105"
                    : status === "disable"
                    ? "text-disabled bg-disabled border border-disabled"
                    : status === "netral"
                    ? "text-textPrimary border border-textPrimary hover:scale-105"
                    : null
            } text-base font-semibold px-5 py-2 border rounded-md duration-200  disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-500  ${className}`}
            disabled={status === "disable" ? true : false}
            onClick={onClick}
            type={type}
        >
            {children !== undefined ? <div className="">{children}</div> : null}

            <div>{title}</div>
        </button>
    );
}
