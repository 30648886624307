import React from "react";
import ContactusContainer from "../../container/ContactUs";

export default function index() {
    return (
        <div>
            <ContactusContainer />
        </div>
    );
}
