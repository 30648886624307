import React, { useEffect } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { RiFileExcel2Fill } from "react-icons/ri";

export default function Modal({ children, header, isShow, setIsShow, className, status, icon }) {
    useEffect(() => {
        if (isShow) {
            document.body.style.overflow = "hidden";
        }

        return () => {
            document.body.style.overflow = "unset";
        };
    }, [isShow]);

    const listIcon = [
        { label: "checklist", icon: <BsExclamationCircleFill color="green" size={25} className="my-auto mx-2" /> },
        { label: "excel", icon: <RiFileExcel2Fill size={25} className="my-auto mx-2" /> },
    ];

    return (
        <div
            className={`fixed inset-0 flex justify-center items-center transition-colors z-50  ${
                isShow === true ? "visible bg-black/40" : "invisible"
            }`}
        >
            <div
                className={`${
                    status === "small"
                        ? "relative w-1/4 max-lg:w-2/3  mx-auto bg-white rounded-lg shadow-lg "
                        : status === "large"
                        ? `relative w-full max-w-7xl  mx-auto bg-white rounded-md shadow-lg  `
                        : "relative w-full max-w-3xl  mx-auto bg-white rounded-md shadow-lg "
                } ${isShow ? "animate-popup  rounded-xl pb-14" : "animate-popup-close"}`}
            >
                {setIsShow && (
                    <button
                        className="absolute bottom-3 text-sm right-3  mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        onClick={() => setIsShow(false)}
                    >
                        Close
                    </button>
                )}
                <div className="bg-white rounded-lg ">
                    {isShow === true ? (
                        <div className="px-3 md:p-7 ">
                            <h4 className=" w-full ">
                                <div className="w-full space-y-5">
                                    <h4 className="text-start text-xl md:text-3xl font-semibold text-textPrimary py-2">{header}</h4>
                                    {children}
                                </div>
                            </h4>
                        </div>
                    ) : null}
                </div>
            </div>
            {/* <div className={`opacity-25 fixed inset-0 z-40 bg-[#000000]`}></div> */}
        </div>
    );
}
