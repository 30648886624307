import React from "react";
import ServicesCointainer from "../../container/Services";

export default function index() {
    return (
        <div>
            <ServicesCointainer />
        </div>
    );
}
