import React, { useState } from "react";
import Header from "../../components/Header";
import clisteLogo from "../../assets/3dLogoCliste.svg";
import BackgroundEngineering from "../../assets/bgEngineeringServices.png";
import { FaArrowRightLong } from "react-icons/fa6";
import img1 from "../../assets/cardIcon/cardIcon1.svg";
import img2 from "../../assets/cardIcon/cardIcon2.svg";

import AssetRegister from "../../assets/servicesPage/CRICapabilities/AssetRegister.svg";
import BadActorManagement from "../../assets/servicesPage/CRICapabilities/BadActorManagement.svg";
import ECA from "../../assets/servicesPage/CRICapabilities/EquipmentCriticalityAnalysis.svg";
import LDA from "../../assets/servicesPage/CRICapabilities/LifeDataAnalysis.svg";
import MFO from "../../assets/servicesPage/CRICapabilities/MaintenancedFrequentlyOptimation.svg";
import RAMAnalysis from "../../assets/servicesPage/CRICapabilities/RamAnalysis.svg";
import RCM from "../../assets/servicesPage/CRICapabilities/ReliabilityCeteredMaintenanced.svg";
import AppSecurity from "../../assets/servicesPage/CRICapabilities/AppSecurity.svg";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

export default function EngineeringServices() {
    const ServicesContent = [
        {
            id: 1,
            title: "Asset Register",
            img: AssetRegister,
            content:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum magni repellat mollitia, officia, neque consectetur aut excepturi esse sapiente repudiandae quam voluptatum! Aperiam voluptatum, culpa non eligendi nesciunt nemo soluta! Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum magni repellat mollitia, officia, neque consectetur aut excepturi esse sapiente repudiandae quam voluptatum! Aperiam voluptatum, culpa non eligendi nesciunt nemo soluta! Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum magni repellat mollitia, officia, neque consectetur aut excepturi esse sapiente repudiandae quam voluptatum! Aperiam voluptatum, culpa non eligendi nesciunt nemo soluta! Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum magni repellat mollitia, officia, neque consectetur aut excepturi esse sapiente repudiandae quam voluptatum! Aperiam voluptatum, culpa non eligendi nesciunt nemo soluta! ",
        },
        { id: 2, title: "Bad Actor Management", img: BadActorManagement },
        { id: 3, title: "Equipment Critical Analysis", img: ECA },
        { id: 4, title: "Life Data Analysis", img: LDA },
        { id: 5, title: "Maintenanced Frequently Optimation", img: MFO },
        { id: 6, title: "RAM Analysis", img: RAMAnalysis },
        { id: 7, title: "Reliability Centered Maintenance", img: RCM },
        { id: 8, title: "Application Security", img: AppSecurity },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const openModal = (service) => {
        setSelectedService(service);
        setIsModalOpen(true);
    };

    return (
        <div>
            {isModalOpen && (
                <Modal isShow={isModalOpen} setIsShow={setIsModalOpen} header={selectedService.title} className="h-[60hv]">
                    <p className="text-gray-700">{selectedService.content}</p>
                </Modal>
                // <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-out">
                //     <div className="bg-white p-6 rounded-lg shadow-lg w-[30rem] transform transition-transform duration-300 ease-out scale-95 animate-popup">
                //         <h2 className="text-xl font-semibold mb-4">{selectedService.title}</h2>
                //         <p className="text-gray-700">{selectedService.content}</p>
                //         <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={closeModal}>
                //             Close
                //         </button>
                //     </div>
                // </div>
            )}
            <Header
                className="flex items-end py-20 "
                bgColor="bg-gradient-to-b from-[#073067] to-[#1E6BCF]"
                height="22rem"
                image={BackgroundEngineering}
            >
                <div className="text-5xl font-semibold z-50">Engineering Services</div>
            </Header>
            <main className="relative py-5 px-36 grid justify-center items-center ">
                <div className="py-11 space-y-10 px-11">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem consequat hendrerit congue accumsan amet purus. Nunc habitasse
                        posuere sollicitudin praesent pulvinar. Potenti suscipit nullam sollicitudin; congue malesuada tempus. Magna vestibulum hac
                        cursus natoque facilisi arcu aliquam sagittis. Sollicitudin luctus suscipit amet elit habitant etiam? Pharetra arcu faucibus
                        euismod leo sagittis parturient.
                    </p>
                    <p>
                        Libero neque felis magna scelerisque montes. Mus congue donec lobortis iaculis class felis posuere eleifend! Congue facilisis
                        posuere dictum condimentum gravida cras convallis. Per temp
                    </p>
                </div>
                <div className="space-y-14 my-10 px-11 h-full">
                    <h1 className="text-center  text-4xl font-bold">CRI Capabilities</h1>
                    <div className="overflow-hidden">
                        <div className="grid grid-cols-4 gap-x-14 gap-y-10 ">
                            {ServicesContent.map((data, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="card w-[15.375rem] h-[15.688rem] bg-[#003478] hover:bg-[#001A3C] transition duration-300 ease-in-out rounded-xl text-white overflow-hidden group"
                                    >
                                        <div className="grid py-5 space-y-3 h-full overflow-hidden">
                                            <div className="px-7 space-y-2">
                                                <h1 className="font-semibold text-xl">{data.title}</h1>
                                                <button
                                                    className="text-xs flex justify-center items-center space-x-2"
                                                    onClick={() => openModal(data)}
                                                >
                                                    <span>More Details</span>
                                                    <FaArrowRightLong className="text-xs group-hover:animate-fade-right" />
                                                </button>
                                            </div>
                                            <img
                                                className="relative translate-x-32 translate-y-10 scale-125 transition-transform duration-300 ease-in-out group-hover:rotate-[15deg]"
                                                src={data.img}
                                                alt={data.title}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
