import React, { useState } from "react";
import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft, FaSearch } from "react-icons/fa";

import { vacancies } from "../../../Data/data";
import { GoPeople } from "react-icons/go";
import { IoBagRemoveOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function OurVacancies() {
    const [searchActive, setSearchActive] = useState(false);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const toggleSearch = () => {
        setSearchActive(!searchActive); // Toggle state search
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const { id } = useParams();
    const vacancy = vacancies.find((vac) => vac.id === parseInt(id));

    const handleReadMore = (id) => {
        navigate(`/career/OurVacancies/detail/${id}`);
    };
    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Career", path: "/career" },
        { label: "Our Vacancies", path: "/career/OurVacancies" },
    ];

    return (
        <div className="bg-[#F6FBFF]">
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[25rem] h-screen xl:px-32 md:px-14 px-10 max-md:hidden "
                position="end"
            >
                {/* <button className="text-base flex justify-center items-center space-x-4 " onClick={handleBack}>
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
                <div className="text-4xl space-y-1">
                    <p className="text-sm font-normal">Work with us</p>
                    <div>Let’s growth your potential with CRI</div>
                </div>
            </Header>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[25rem] h-screen xl:px-32 md:px-14 px-10 md:hidden"
                position="center"
            >
                {/* <button className="text-base flex justify-center items-center space-x-4 " onClick={handleBack}>
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
                <div className="text-4xl space-y-1 ">
                    <p className="text-sm font-normal">Work with us</p>
                    <div>Let’s growth your potential with CRI</div>
                </div>
            </Header>
            <Breadcrumbs breadcrumbs={breadcrumb} />
            <div className="xl:px-32 lg:px-20 md:px-16 py-10 text-[#616161]">
                <div className=" w-full space-y-10">
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-4xl font-semibold mb-6 max-md:px-5">Our Available Vacancies</h1>
                        <div className={`relative flex items-center transition-all duration-300 ease-in-out ${searchActive ? "w-96 " : "w-12"}`}>
                            <input
                                type="text"
                                className={`absolute  bg-[#FFFFFF1A]/10 h-full font-normal text-sm  text-black border-none outline-none transition-all duration-300 ease-in-out z-10  ${
                                    searchActive ? "opacity-100 w-2/3 h-full pl-4" : "opacity-0 w-0"
                                }`}
                                placeholder="Input a role, position, or other keyword"
                            />
                            {/* <button
                                onClick={toggleSearch}
                                className="flex items-center justify-end w-full  bg-[#FFFFFF1A]/10 text-black hover:border-white px-4 py-2 hover:border-2 rounded-lg border border-teal-100 transition-all duration-300 ease-in-out z-0"
                            >
                                <FaSearch />
                            </button> */}
                        </div>
                    </div>
                    <div className="space-y-10 w-full">
                        {/* <h1 className="text-center">Showing 5 available vacancies</h1> */}
                        <div className="flex max-xl:flex-col justify-center items-start w-full h-full">
                            <div className="md:w-1/4 max-md:my-10 flex flex-col  max-md:justify-start items-start h-full w-full xl:space-y-10 max-sm:text-xs">
                                <div className="xl:px-10 px-5 ">
                                    <h1>Level</h1>
                                    <input type="search" />
                                    <div className="h-full xl:flex-col flex xl:justify-start xl:items-start justify-center items-center xl:space-y-2 md:space-x-4 space-x-2 xl:space-x-0">
                                        <div className="flex items-center md:space-x-3 space-x-1">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="checkbox" className="text-gray-700 font-light">
                                                {/* {isChecked ? "Checked" : "Unchecked"} */}
                                                All
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="checkbox" className="text-gray-700 font-light">
                                                {/* {isChecked ? "Checked" : "Unchecked"} */}
                                                Experienced
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="checkbox" className="text-gray-700 font-light">
                                                {/* {isChecked ? "Checked" : "Unchecked"} */}
                                                Fresh Graduated
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="checkbox" className="text-gray-700 font-light">
                                                {/* {isChecked ? "Checked" : "Unchecked"} */}
                                                Internship
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:px-10 px-5 ">
                                    <h1>Department</h1>
                                    <input type="search" />
                                    <div className="h-full xl:flex-col flex xl:justify-start xl:items-start justify-center items-center xl:space-y-2 md:space-x-4 space-x-2 xl:space-x-0">
                                        <div className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="checkbox" className="text-gray-700 font-light">
                                                {/* {isChecked ? "Checked" : "Unchecked"} */}
                                                All
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="checkbox" className="text-gray-700 font-light">
                                                {/* {isChecked ? "Checked" : "Unchecked"} */}
                                                Engineer
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="checkbox" className="text-gray-700 font-light">
                                                {/* {isChecked ? "Checked" : "Unchecked"} */}
                                                IT
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="checkbox" className="text-gray-700 font-light">
                                                {/* {isChecked ? "Checked" : "Unchecked"} */}
                                                Office
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 h-full w-full">
                                <div className="h-full grid gap-6">
                                    {vacancies.map((vacancy) => (
                                        <div
                                            key={vacancy.id}
                                            className="relative p-6 bg-white/30 rounded-lg shadow-md md:space-y-3 space-y-5 hover:cursor-pointer hover:bg-white/100 transition duration-300 ease-in-out md:px-5"
                                            onClick={() => handleReadMore(vacancy.id)}
                                        >
                                            <h2 className="text-xl font-semibold">{vacancy.title}</h2>
                                            <div className="flex md:space-x-10 max-md:space-x-3 text-sm">
                                                <div className="flex space-x-5 justify-center items-center">
                                                    <GoPeople />
                                                    <h1>{vacancy.workingMode}</h1>
                                                </div>
                                                <div className="flex space-x-5 justify-center items-center">
                                                    <IoBagRemoveOutline />
                                                    <h1>{vacancy.level}</h1>
                                                </div>
                                                <div className="flex space-x-5 justify-center items-center">
                                                    <CiLocationOn />
                                                    <h1>{vacancy.location}</h1>
                                                </div>
                                            </div>
                                            <div className="md:absolute right-10 bottom-10 ">
                                                <button onClick={() => handleReadMore(vacancy.id)}>Read More</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
