import React from "react";

import OurInsightContainer from "../../container/OurInsight/index";
export default function index() {
    return (
        <div>
            <OurInsightContainer />
        </div>
    );
}
