import React from "react";
import HomeContainer from "../../container/Home/index";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

export default function index() {
    return (
        <div className="h-full">
            <HomeContainer />
        </div>
    );
}
