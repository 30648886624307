import React from "react";
import clistelogo from "../assets/cliste-logo.png";
import iso from "../assets/iso.png";
import { FaGlobe, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

export default function Footer() {
    return (
        <div className="bg-[#003478] text-white text-xl px-6 xl:px-36 md:px-10 py-10 md:py-5 w-full">
            <div className="lg:flex w-full space-y-10 lg:space-y-0 py-5">
                <div className="space-y-6 font-light lg:w-[45rem]">
                    <img src={clistelogo} alt="Cliste Logo" />
                    <section className="text-sm max-w-md font-normal">
                        Indonesia-based consultant pioneer in advanced Safety & Reliability asset management assessment, precise reverse engineering,
                        innovative software, and excellent new product development.
                    </section>
                    {/* <p className="text-sm">Help any support?</p> */}
                    {/* <button className="flex items-center justify-center bg-white w-full md:w-32 h-12 text-[#585858] text-sm space-x-2 font-semibold rounded-md">
                        <img className="w-5" src={mailicon} alt="Mail Icon" />
                        <span>Contact Us</span>
                    </button> */}
                </div>

                <div className=" space-y-8 font-light text-xs flex-1">
                    {/* <div className="flex space-x-4 items-start">
                        <FaMapMarkerAlt className="text-white h-5 w-5" />
                        <div className="space-y-2">
                            <div className="">
                                <h3 className="font-semibold">Head Office :</h3>
                                <p>Gandaria 8 Office Tower 8 Floor Jl. Sultan Iskandar Muda, Jakarta Selatan, 12440</p>
                            </div>
                            <div className="">
                                <h3 className="font-semibold">Remote Office :</h3>
                                <p>
                                    Lebak Lestari Indah Residence Jl. Cempaka Lestari IV No.3 5, RT.14/RW.7, Lb. BulusKec, Kec. Cilandak, Kota Jakarta
                                    Selatan, Daerah Khusus Ibukota Jakarta 12440
                                </p>
                            </div>
                        </div>
                    </div> */}
                    <div className="flex justify-center space-x-2 items-start">
                        <FaMapMarkerAlt className="text-white h-5 w-5" />
                        <div className="flex-1 space-y-2 px-2">
                            <h3 className="font-semibold">Head Office :</h3>
                            <p>Gandaria 8 Office Tower 8 Floor Jl. Sultan Iskandar Muda, Jakarta Selatan, 12440</p>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-2 items-start">
                        <FaMapMarkerAlt className="text-white h-5 w-5" />
                        <div className="flex-1 space-y-2 px-2">
                            <h3 className="font-semibold">Remote Office :</h3>
                            <p>
                                <a href="https://maps.app.goo.gl/6wtr1ec1R6xBJvV59" className="hover:text-blue-200">
                                    Lebak Lestari Indah Residence Jl. Cempaka Lestari IV No.3 5, RT.14/RW.7, Lb. BulusKec, Kec. Cilandak, Kota Jakarta
                                    Selatan, Daerah Khusus Ibukota Jakarta 12440
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="flex space-x-4 items-start">
                        <FaPhoneAlt className="text-white h-5 w-5" />
                        <p className="flex-1">+62 21 29851728 / +62 812 1981 0869</p>
                    </div>
                    <div className="flex space-x-4 items-start">
                        <FaGlobe className="text-white h-5 w-5" />
                        <p className="flex-1">www.cliste.co.id</p>
                    </div>
                    <div className="relative w-full">
                        <img
                            className="hidden md:block absolute lg:-right-1 lg:-bottom-16 md:right-5 max-md:right-0 bottom-0 bot md:w-32 "
                            src={iso}
                            alt="ISO Certification"
                        />
                    </div>
                </div>
            </div>

            <div className="w-full h-px bg-[#B4B4B4] my-8 md:my-14"></div>

            <p className="text-center text-gray-100 text-sm">© 2024 PT. Cliste Rekayasa Indonesia</p>
        </div>
    );
}
